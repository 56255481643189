import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { countryCodes, countryCodesDictionary, regionDictionary, supportedCurrencies } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Cart from "assets/javascript/cart";
import * as Proton from "assets/javascript/proton";

import Breadcrumb from "components/breadcrumb"

var supportedCurrenciesCopy = [...supportedCurrencies];

function Checkout(error) {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ prices: {}, payments: {}, selectedBilling: 0, selectedShipping: 0, shippingLocation: "", shippingTotal: 0, checkoutDisabled: true, orderNotes: "" });
    const [data, setData] = useState({ blockchainIds: {}, shippingInfo: {}, paymentInfo: {}, escrowInfo: {} });

    useEffect(() => {
        Proton.fetchStorexOracles().then(res => {
            var priceObj = {};

            res.rows.forEach((obj, index) => {
                var [, ticker] = obj.sym.split(",");
                var [price] = obj.quantity.split(" ");

                priceObj[ticker] = price;

                if (index == res.rows.length-1) {
                    setInputs(values => (
                        {
                            ...values,
                            prices: priceObj
                        }
                    ));
                };
            });
        });

        // fetch user balances on blockchain


        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

        return () => {
            clearTimeout(window.rpcRetry);
            clearTimeout(window.balanceCheck);
        };
    }, []);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/checkout"
            });
            
            return navigate("/", { replace: true });
        };

        if (state.authenticated) handleBalanceFetch();

        if (state.authenticated && state.account.deliveryAddress.length < 1) return determineLocation();

        setLocation(state.account?.deliveryAddress?.[0]?.country);
    }, [state.account]);

    useEffect(() => {
        if (state.cart.length > 0) calculateShipping();
    }, [state, inputs.selectedBilling, inputs.selectedShipping]);

    useEffect(() => {
        if (state.selectedBillingAddress == null) return;

        setInputs(values => (
            { ...values, selectedBilling: state.selectedBillingAddress }
        ));

        $("div.input-modal, section").removeClass("active");
    }, [state.selectedBillingAddress]);

    useEffect(() => {
        if (state.selectedShippingAddress == null) return;

        setInputs(values => (
            { ...values, selectedShipping: state.selectedShippingAddress }
        ));

        $("div.input-modal, section").removeClass("active");
    }, [state.selectedShippingAddress]);

    function determineLocation() {
        const f = async () => {
            var req = await fetch("https://ipinfo.io/json?token=d637d120b18ed1");
            var data = await req.json();

            if (data.hasOwnProperty("country")) {
                if (regionDictionary[data.region]) {
                    setLocation(regionDictionary[data.region]);
                } else {
                    setLocation(data.country);
                };
            };
        };

        f();
    };

    function setLocation(countryCode) {
        if (!countryCode) return;

        setInputs(values => ({ ...values, shippingLocation: countryCode }));
    };

    function handleChange(event) {
        const name = event.target.name;
        const value = event.target.value;

        setInputs(values => (
            {
                ...values,
                [name]: value
            }
        ));
    };

    function handleBalanceFetch() {
        var balances = {};
        var prices = {};
        var oracles = {};

        Proton.fetchOracles().then(res => {
            var oracleArr = res?.rows || [];

            oracleArr.forEach((feed, index) => {
                var feedIndex = feed?.feed_index || 0;
                var feedPrice = feed?.aggregate?.d_double || 0;

                oracles[feedIndex] = feedPrice;

                if (index == oracleArr.length-1) {
                    supportedCurrenciesCopy.forEach((currency, index) => {
                        Proton.fetchBalance(state.session.auth.actor, currency).then(res => {
                            const [balanceStr] = res;
                            const [balance, ticker] = (balanceStr || "").split(" ");
            
                            balances[currency.ticker] = balance || "0";

                            prices[currency.ticker] = oracles[currency.feedIndex];

                            if (index == supportedCurrenciesCopy.length-1) {
                                Proton.fetchStorexOracles().then(res => {
                                    var oracleArr = res?.rows || [];
                        
                                    oracleArr.forEach((feed, index) => {
                                        const {sym, quantity} = feed;
                                        const [, ticker] = sym.split(",");
                                        const [amount] = quantity.split(" ");
                        
                                        prices[ticker] = amount;

                                        if (index == oracleArr.length-1) {
                                            setData(values => (
                                                { ...values, balances: balances, prices: prices }
                                            ));
                                        };
                                    });
                                }).catch(err => {
                                    console.log(err);
                        
                                    setTimeout(() => {
                                        handleBalanceFetch();
                                    }, 3000);
                                });
                            };
                        }).catch(err => {
                            console.log(err);
                
                            setTimeout(() => {
                                handleBalanceFetch();
                            }, 3000);
                        });
                    });
                };
            });
        }).catch(err => {
            console.log(err);

            setTimeout(() => {
                handleBalanceFetch();
            }, 3000);
        });

        window.balanceCheck = setTimeout(() => {
            handleBalanceFetch();
        }, 30000);
    };

    function handlePaymentChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        var [id, variation, ticker] = event.target.id.split("-");

        setInputs(values => (
            {
                ...values,
                payments: {
                    ...values.payments,
                    [id]: ticker
                }
            }
        ));
    };

    function handlePurchase(event) {
        event.preventDefault();

        if (inputs.checkoutDisabled) return;

        var paymentCheck = state.cart.every((listing, index) => {
            if ($("input[name='payment-method-" + listing._id + "-" + listing.variation.id + "']:checked").length < 1) {
                $("input[name='payment-method-" + listing._id + "-" + listing.variation.id + "']").each((i, e) => {
                    $(e).next().css("border-color", "#d35f5f");

                    setTimeout(() => {
                        $(e).next().css("border-color", "");
                    }, 1000);
                });

                window.scrollTo({ top: $("input[name='payment-method-" + listing._id + "-" + listing.variation.id + "']").closest(".item").offset().top - 12, left: 0, behavior: "smooth" });

                return false;
            };

            return true;
        });

        var successOrder = (paymentLog, listingArr) => {
            if (process.env.REACT_APP_WEB2_LOGIN) {
                var formedActions = Object.keys(paymentLog).map(id => {
                    var token = paymentLog[id];
        
                    return {
                        account: token.contract,
                        name: "transfer",
                        data: {
                            from: state.session.auth.actor,
                            to: "storexcore",
                            quantity: token.quantity,
                            memo: "purchase #" + id
                        },
                        authorization: [state.session.auth]
                    };
                });

                var successPayment = (tx) => {
                    if (!tx.processed) return errorPurchase("The transaction has not been broadcast to the blockchain, please try again");
        
                    var txId = tx.processed?.id || "";
        
                    var successPurchase = () => {
                        $("button.checkout").html(textStrings["place_order"][state.language] + "<i class='fal fa-long-arrow-right no-spin'></i>");
        
                        dispatch({
                            type: "setCart",
                            value: []
                        });
        
                        dispatch({
                            type: "setOrder",
                            value: {
                                txId: txId,
                                orderId: Object.keys(paymentLog)[0]
                            }
                        });
        
                        navigate("/order-confirmation");
                    };
        
                    var errorPurchase = (error) => {
                        $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");
        
                        $("button.checkout").html(textStrings["place_order"][state.language] + "<i class='fal fa-long-arrow-right no-spin'></i>");
                    };
        
                    Listing.purchaseListing(state.session.auth.actor, txId, listingArr, state.account?.deliveryAddress[inputs.selectedShipping], inputs.orderNotes).then(res => {
                        successPurchase();
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };
        
                        if (message?.indexOf("Missing Cookie") != -1) {
                            Account.requestToken(state.session.auth.actor).then(res => {
                                Listing.purchaseListing(state.session.auth.actor, txId, listingArr, state.account?.deliveryAddress[inputs.selectedShipping], inputs.orderNotes).then(res => {
                                    successPurchase();
                                }).catch(err => {
                                    var { message } = err.responseJSON || { message: err };
        
                                    errorPurchase(message);
                                });
                            }).catch(err => {
                                $("div.error-modal").addClass("error").find("div.text").html("There was an error authenticating your request, please try again");
        
                                navigate("/");
                            });
                        } else {
                            errorPurchase(message);
                        };
                    });
                };
        
                var errorPayment = (error) => {
                    $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");
        
                    $("button.checkout").html(textStrings["place_order"][state.language] + "<i class='fal fa-long-arrow-right no-spin'></i>");
                };
        
                Proton.purchaseListings(state, formedActions).then(tx => {
                    successPayment(tx);
                }).catch(err => {
                    errorPayment(err.error?.details?.[0]?.message || err);
                });
            } else {
                $("div.input-modal").addClass("active").find("section.make-payment").addClass("active");

                dispatch({
                    type: "setModal",
                    value: {
                        action: "make-payment",
                        payment: paymentLog,
                        listings: listingArr,
                        delivery: state.account?.deliveryAddress[inputs.selectedShipping],
                        notes: inputs.orderNotes
                    }
                });

                $("button.checkout").html("<i class='fad fa-spinner-third'></i> Awaiting payment");
            };
        };

        var errorOrder = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error processing your request, please try again");

            $("button.checkout").html(textStrings["place_order"][state.language] + "<i class='fal fa-long-arrow-right no-spin'></i>");
        };

        if (paymentCheck) {
            $("button.checkout").html("<i class='fad fa-spinner-third'></i> Placing order");

            var listingArr = state.cart.map(listing => {
                var ticker = inputs.payments[listing._id];

                var paymentMeta = {};

                var paymentMetaCheck = supportedCurrencies.every(currency => {
                    if (currency.ticker == ticker) {
                        paymentMeta.sym = currency.precision + "," + currency.ticker;
                        paymentMeta.contract = currency.contract;

                        return false;
                    };

                    return true;
                });

                if (paymentMetaCheck) console.log("error: empty payment meta");

                return {
                    id: data.blockchainIds[listing._id],
                    variationId: listing.variation.id,
                    shippingCountryCode: inputs.shippingLocation,
                    payment: paymentMeta,
                    quantity: listing.quantity
                }
            });

            Proton.checkoutListings(state, listingArr).then(tx => {
                if (!tx.processed) return errorOrder("The transaction has not been broadcast to the blockchain, please try again");

                var txId = tx.processed?.id || "";
                var rpcAttempt = 0;

                checkTx(txId);

                function checkTx(txId) {
                    Proton.fetchTx(txId).then(res => {
                        var { code, message } = res;
    
                        if (code || message) {
                            if (rpcAttempt >= 30) {
                                return errorOrder("RPC Error: Unable to fetch transaction from blockchain");
                            };
            
                            rpcAttempt++;
            
                            window.rpcRetry = setTimeout(() => {
                                checkTx(txId);
                            }, 1000);
                        } else {
                            var traces = res.traces;

                            if (traces.length < 1) return errorOrder("RPC Error: Transaction contains no traces");

                            var paymentLog = {};

                            traces.forEach(trace => {
                                if (trace.act.account == "storexcore") {
                                    if (trace.act.name == "log.order") {
                                        var { order_id, quantity } = trace.act.data;

                                        paymentLog[order_id] = quantity;
                                    };
                                };
                            });

                            var orderIds = Object.keys(paymentLog);

                            if (orderIds.length < 1) return errorOrder("RPC Error: No order_id found");

                            successOrder(paymentLog, listingArr);
                        };
                    }).catch(err => {
                        if (rpcAttempt >= 30) {
                            return errorOrder("RPC Error: Unable to fetch transaction from blockchain");
                        };
        
                        rpcAttempt++;
        
                        window.rpcRetry = setTimeout(() => {
                            checkTx(txId);
                        }, 1000);
                    });
                };
            }).catch(err => {
                errorOrder(err.error?.details?.[0]?.message || err);
            });
        };
    };

    function calculateShipping() {
        var success = (res) => {
            var blockchainIds = res.blockchainIds;
            var shippingDetails = res.shippingDetails;
            var paymentDetails = res.paymentDetails;

            var shippingTotal = Object.keys(shippingDetails).reduce((a, b) => {
                let cost = shippingDetails[b]?.[state.account?.deliveryAddress?.[inputs.selectedShipping]?.country] || 0;
                
                return a + parseFloat(cost);
            }, 0);

            var shippingCheck = Object.keys(shippingDetails).every((id) => {
                let cost = shippingDetails[id]?.[state.account?.deliveryAddress?.[inputs.selectedShipping]?.country] || null;

                if (cost == null) return false;

                return true;
            });

            var iconObj = {};

            Object.keys(paymentDetails).forEach(id => {
                iconObj[id] = paymentDetails[id][0].logo;
            });

            setData(values => (
                {
                    ...values,
                    shippingInfo: shippingDetails,
                    paymentInfo: paymentDetails,
                    blockchainIds: blockchainIds,
                    escrowInfo: res.escrowDetails
                }
            ));

            setInputs(values => (
                {
                    ...values,
                    shippingTotal: shippingTotal,
                    paymentIcons: iconObj,
                    checkoutDisabled: !shippingCheck,
                    shippingLocation: state.account?.deliveryAddress?.[inputs.selectedShipping]?.country
                }
            ));
        };

        var error = (err) => {
            $("div.error-modal").addClass("error").find("div.text").html(err || "There was an error processing your request, please try again");
        };

        Cart.fetchListingDetails(state.cart.map(listing => listing._id)).then(res => {
            success(res);
        }).catch(err => {
            var { message } = err.responseJSON || { message: err };

            if (message?.indexOf("Missing Cookie") != -1) {
                Account.requestToken(state.session.auth.actor).then(res => {
                    Cart.fetchListingDetails(state.cart.map(listing => listing._id)).then(res => {
                        success(res);
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };

                        error(message);
                    });
                }).catch(err => {
                    var { message } = err.responseJSON || { message: err };

                    error(message);
                });
            } else {
                var { message } = err.responseJSON || { message: err };

                error(message);
            };
        });
    };

    function handleNewAddress(addressType) {
        $("div.input-modal").addClass("active").find("section.new-address").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "add",
                type: addressType
            }
        });
    };

    function handleChangeAddress(addressType) {
        $("div.input-modal").addClass("active").find("section.change-address").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "change",
                type: addressType,
                selected: addressType == "delivery" ? inputs.selectedShipping : inputs.selectedBilling
            }
        });
    };

    function handleCart(addItem, itemId, variation, quantity) {
        if (!addItem) {
            var showSuccess = (res) => {
                dispatch({
                    type: "setCart",
                    value: res
                });

                //$("div.error-modal").addClass("success").find("div.text").html("Removed listing from shopping cart");
            };

            var showError = () => {
                $("div.error-modal").addClass("error").find("div.text").html("There was an error removing listing from shopping cart");
            };

            Cart.removeListing(state.session.auth.actor, itemId, variation, quantity || 1).then(res => {
                showSuccess(res);
            }).catch(error => {
                console.log(error);
                var { message } = error.responseJSON;

                if (message?.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Cart.removeListing(state.session.auth.actor, itemId, variation, quantity || 1).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            showError();
                        });
                    }).catch(err => {
                        showError();
                    });
                } else {
                    showError();
                };
            });
        } else {
            var showSuccess = (res) => {
                dispatch({
                    type: "setCart",
                    value: res.cart
                });

                if (res.exceedStock) {
                    //$("div.error-modal").addClass("warning").find("div.text").html("Added listing to shopping cart with remaining stock");
                } else {
                    //$("div.error-modal").addClass("success").find("div.text").html("Added listing to shopping cart");
                };
            };

            var showError = () => {
                $("div.error-modal").addClass("error").find("div.text").html("There was an error adding listing to shopping cart");
            };

            Cart.addListing(state.session.auth.actor, itemId, variation, quantity || 1).then(res => {
                showSuccess(res);
            }).catch(error => {
                console.log(error);
                var { message } = error.responseJSON;

                if (message?.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Cart.addListing(state.session.auth.actor, itemId, variation, quantity || 1).then(res => {
                            showSuccess(res);
                        }).catch(err => {
                            showError();
                        });
                    }).catch(err => {
                        showError();
                    });
                } else {
                    showError();
                };
            });
        };
    };

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: textStrings["my_cart"][state.language], path: "/cart" },
        { label: textStrings["checkout"][state.language], path: "/checkout" }
    ]

    return (
        <section className="checkout">
            <div className="page-header">Checkout</div>

            <div className="container">
                <div className="breadcrumb-wrapper">
                    <div className="breadcrumb">
                        <Breadcrumb crumbs={breadcrumbs} />
                    </div>
                </div>

                <div className="checkout-wrapper">
                    <div className="checkout-contents">
                        <div className="addresses">
                            <div className="billing-details">
                                <div className="subtitle">Billing Address</div>
                                {
                                    (state.account?.billingAddress?.length > 0) && (
                                        <div className="address">
                                            <div className="name">{ state.account?.billingAddress[inputs.selectedBilling]?.fullName }</div>
                                            <div className="address">
                                                { state.account?.billingAddress[inputs.selectedBilling]?.addressLine1 }
                                                {
                                                    (state.account?.billingAddress[inputs.selectedBilling]?.addressLine2) && (
                                                        <span>, { state.account?.billingAddress[inputs.selectedBilling]?.addressLine2 }</span>
                                                    )
                                                }
                                            </div>
                                            <div className="city">{ state.account?.billingAddress[inputs.selectedBilling]?.cityTown }</div>
                                            <div className="zipcode">{ state.account?.billingAddress[inputs.selectedBilling]?.zipcode }</div>
                                            <div className="country">{ countryCodesDictionary[state.account?.billingAddress[inputs.selectedBilling]?.country] }</div>
                                        </div>
                                    )
                                }
                                
                                {
                                    (state.account?.billingAddress?.length > 0) && (
                                        <button className="edit" onClick={ () => handleChangeAddress("billing") }>Change Billing Address</button>
                                    )
                                }

                                {
                                    (state.account?.billingAddress?.length < 1) && (
                                        <button className="edit" onClick={ () => handleNewAddress("billing") }>Add Billing Address</button>
                                    )
                                }
                            </div>

                            <div className="shipping-details">
                                <div className="subtitle">Shipping Address</div>
                                {
                                    (state.account?.deliveryAddress?.length > 0) && (
                                        <div className="address">
                                            <div className="name">{ state.account?.deliveryAddress[inputs.selectedShipping]?.fullName }</div>
                                            <div className="address">
                                                { state.account?.deliveryAddress[inputs.selectedShipping]?.addressLine1 }
                                                {
                                                    (state.account?.deliveryAddress[inputs.selectedShipping]?.addressLine2) && (
                                                        <span>, { state.account?.deliveryAddress[inputs.selectedShipping]?.addressLine2 }</span>
                                                    )
                                                }
                                            </div>
                                            <div className="city">{ state.account?.deliveryAddress[inputs.selectedShipping]?.cityTown }</div>
                                            <div className="zipcode">{ state.account?.deliveryAddress[inputs.selectedShipping]?.zipcode }</div>
                                            <div className="country">{ countryCodesDictionary[state.account?.deliveryAddress[inputs.selectedShipping]?.country] }</div>
                                        </div>
                                    )
                                }

                                {
                                    (state.account?.deliveryAddress?.length > 0) && (
                                        <button className="edit" onClick={ () => handleChangeAddress("delivery") }>Change Shipping Address</button>
                                    )
                                }

                                {
                                    (state.account?.deliveryAddress?.length < 1) && (
                                        <button className="edit" onClick={ () => handleNewAddress("delivery") }>Add Shipping Address</button>
                                    )
                                }
                            </div>
                        </div>

                        <div className="shipping-services">
                            <div className="subtitle">Payment Options</div>
                            {
                                (state.cart?.length < 1) && (
                                    <div className="empty">Your shopping cart is empty</div>
                                )
                            }

                            {
                                (state.cart.length > 0) && (
                                    state.cart.map((item, index) => {
                                        return (
                                            <div key={ index } className={ !data.shippingInfo?.[item._id]?.[state.account?.deliveryAddress?.[inputs.selectedShipping]?.country] ? "item disabled" : "item" }>
                                                <div>
                                                    <div className="mobile-div">
                                                        <Link to={ "/listing/" + item._id }><div className="thumbnail"><img src={ item.thumbnail } alt={ item.title }/></div></Link>

                                                        <div className="details">
                                                            <Link to={ "/listing/" + item._id }><div className="title">{ item.title }</div></Link>

                                                            <div className="price">{ parseFloat(item.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) } <span>x { item.quantity }</span></div>

                                                            {
                                                                (item.variation.option0) && (
                                                                    <span className="variation">
                                                                        <p>{ item.variation.option0 }</p>

                                                                        {
                                                                            (item.variation.option1) && (
                                                                                <>
                                                                                    <p>/</p>
                                                                                    <p>{ item.variation.option1 }</p>
                                                                                </>
                                                                            )
                                                                        }

                                                                        {
                                                                            (item.variation.option2) && (
                                                                                <>
                                                                                    <p>/</p>
                                                                                    <p>{ item.variation.option2 }</p>
                                                                                </>
                                                                            )
                                                                        }
                                                                    </span>
                                                                )
                                                            }

                                                            <div className="quantity"><i className="fal fa-minus-circle" onClick={() => handleCart(false, item._id, item.variation.id, 1)} /> {item.quantity} <i className="fal fa-plus-circle" onClick={() => handleCart(true, item._id, item.variation.id, 1)} /></div>
                                                        </div>
                                                    </div>

                                                    <div className="mobile-div">
                                                        <div className="shipping-options">
                                                            <div>
                                                                <input type="radio" id={"o" + index} name={"shipping" + index} onChange={() => { }} aria-label="Shipping option" checked />
                                                                <label htmlFor={ "o" + index }>
                                                                    <div>Delivery Service</div>
                                                                    <div className="estimate">Estimated Delivery: 1-2 days</div>
                                                                    {
                                                                        (data.shippingInfo?.[item._id]?.[state.account?.deliveryAddress?.[inputs.selectedShipping]?.country]) && (
                                                                            <div className={ parseFloat(data.shippingInfo?.[item._id]?.[state.account?.deliveryAddress?.[inputs.selectedShipping]?.country]) == 0 ? "price free" : "price" }>{ parseFloat(data.shippingInfo?.[item._id]?.[state.account?.deliveryAddress?.[inputs.selectedShipping]?.country]) == 0 ? "FREE" : parseFloat(data.shippingInfo?.[item._id]?.[state.account?.deliveryAddress?.[inputs.selectedShipping]?.country]).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>
                                                                        )
                                                                    }

                                                                    {
                                                                        (Object.keys(data.shippingInfo).length > 0 && state.account.deliveryAddress.length > 0 && !data.shippingInfo?.[item._id]?.[state.account?.deliveryAddress?.[inputs.selectedShipping]?.country]) && (
                                                                            <div className="price unavailable">Shipping not available to { countryCodesDictionary[inputs.shippingLocation] }</div>
                                                                        )
                                                                    }

                                                                    {
                                                                        (state.account.deliveryAddress.length < 1) && (
                                                                            <div className="price unavailable">Please select a shipping address</div>
                                                                        )
                                                                    }
                                                                </label>
                                                            </div>
                                                        </div>

                                                        <div className="escrow-status">
                                                            {
                                                                (data.escrowInfo[item._id]) && (
                                                                    <>
                                                                        <i className="fal fa-shield-check" />
                                                                        <p className="n768">Escrow Transaction <i className="fal fa-question-circle" title="The funds are held in escrow until you confirm receipt of the goods, affording you additional protection on the blockchain." /></p>
                                                                    </>
                                                                )
                                                            }

                                                            {
                                                                (!data.escrowInfo[item._id]) && (
                                                                    <>
                                                                        <i className="fal fa-shield warning" />
                                                                        <p className="n768">Direct Transaction <i className="fal fa-question-circle" title="The funds are sent directly to the seller and you do not have additional protection in the event of a dispute." /></p>
                                                                    </>
                                                                )
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="payment-options">
                                                    {
                                                        (data.shippingInfo?.[item._id]?.[state.account?.deliveryAddress?.[inputs.selectedShipping]?.country]) && (
                                                            data.paymentInfo[item._id]?.map((currency, index) => (
                                                                <div key={ index } className="input-group">
                                                                    <input type="radio" id={item._id + "-" + item.variation.id + "-" + currency.sym.split(",")[1]} name={"payment-method-" + item._id + "-" + item.variation.id} onChange={handlePaymentChange} aria-label="Address" required />
                                                                    <label htmlFor={ item._id + "-" + item.variation.id + "-" + currency.sym.split(",")[1] }>
                                                                        <img className="icon" src={ currency.logo } alt={currency.name} />
                                                                        <div className="value">~{ (((parseFloat(item.price) * item.quantity) + parseFloat(data.shippingInfo[item._id]?.[state.account?.deliveryAddress?.[inputs.selectedShipping]?.country])) / inputs.prices[currency.sym.split(",")[1]]).toLocaleString('en-US', { minimumFractionDigits: currency.sym.split(",")[0] }) } <span>{ currency.name }</span></div>
                                                                        {(currency.name == "STRX") && (<i className="fal fa-badge-dollar" title="Eligible for cashback" />)}
                                                                    </label>
                                                                </div>
                                                            ))
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        )
                                    })
                                )
                            }
                        </div>

                        {
                            /*
                                <div className="order-notes">
                                    <div className="subtitle">Order Notes</div>

                                    <textarea placeholder="Enter any order notes for the seller" name="orderNotes" value={ inputs.orderNotes || "" } onChange={ handleChange }/>
                                </div>
                            */
                        }
                    </div>

                    <div className="checkout-sidebar">
                        <div className="cart-summary">
                            <div className="subtitle">Summary</div>

                            <div className="cart-items n600">
                                {
                                    (state.cart.length > 0) && (
                                        state.cart.map((item, index) => {
                                            return (
                                                <div key={ index } className="item">
                                                    <Link to={ "/listing/" + item._id }><div className="thumbnail"><img src={ item.thumbnail } alt={ item.title }/></div></Link>

                                                    <div className="details">
                                                        <Link to={ "/listing/" + item._id }><div className="title">{ item.title }</div></Link>

                                                        <div className="price">{ parseFloat(item.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</div>

                                                        <div className="quantity">Quantity: { item.quantity }</div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    )
                                }

                                {
                                    (state.cart.length < 1) && (
                                        <div className="empty">Your shopping cart is empty</div>
                                    )
                                }
                            </div>

                            <div className="subtotal">
                                <p>Subtotal</p>
                                <span>{ state.cart.reduce((a, b) => a = a + parseFloat(parseFloat(b.price) * b.quantity), 0).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</span>
                            </div>

                            <div className="subtotal">
                                <p>Shipping</p>
                                <span>{ inputs.shippingTotal.toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</span>
                            </div>

                            <div className="total">
                                <p>Total</p>
                                <span>{ state.cart.reduce((a, b) => a = a + parseFloat(parseFloat(b.price) * b.quantity), inputs.shippingTotal).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 }) }</span>
                            </div>

                            <Link to="/checkout"><button type="submit" className={inputs.checkoutDisabled ? "checkout disabled" : "checkout"} onClick={handlePurchase}>{textStrings["place_order"][state.language]} <i className="fal fa-long-arrow-right no-spin" /></button></Link>
                        </div>

                        <div className="cashback">
                            {
                                (Object.keys(inputs.payments)?.filter(p => JSON.stringify(inputs.payments[p]).includes("STRX")).length > 0) && (
                                    <p>You can earn <span className="symbol">STRX</span> cashback with this purchase</p>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Checkout;
