import { React, useState, useEffect, useMemo } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Cookies from "js-cookie";
import $ from "jquery";

import { useStateValue } from "state";
import { dummyTx, countryCodes, countryCodesDictionary, regionCodesDictionary, regionDictionary, dummyCategories, maxTitleLength, maxDescriptionLength, acceptedImageTypes, maxImagesLength, maxImagesSize, maxOptionLength, filterConditions, supportedCurrencies, filterBuyerKYC } from "constants";
import { textStrings } from "localization";

import * as Account from "assets/javascript/account";
import * as Listing from "assets/javascript/listing";
import * as Proton from "assets/javascript/proton";

import Breadcrumb from "components/breadcrumb"

import placeholderThumbnail from "../assets/images/default_thumbnail.webp"

function CreateListing() {
    const navigate = useNavigate();
    const [state, dispatch] = useStateValue();
    const [inputs, setInputs] = useState({ description: "", category: "", scrollTop: 0, scrollBottom: 3, option0: [], option1: [], option2: [], options: [], variation: 0, variations: [{}], internationalShipping: [], freeDomestic: false, location: "", stock: 0 });
    const [data, setData] = useState({ categories: dummyCategories });

    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }, []);

    useEffect(() => {
        $("textarea").each(function () {
            this.setAttribute("style", "height:" + this.scrollHeight + "px; overflow-y: hidden");
        }).on("input", function () {
            this.style.height = 0;
            this.style.height = this.scrollHeight + "px";
        });
    }, [inputs.description]);

    useEffect(() => {
        if (!state.createListing) return;

        setInputs(values => (
            {
                ...values,
                category: state.createListing?.category,
                subcategory: state.createListing?.subcategory,
                condition: state.createListing?.condition,
                thumbnail: state.createListing?.thumbnail, // when submitting listing, check if thumbnail/images are URLs (imports) and handle differently
                images: state.createListing?.images, // when submitting listing, check if thumbnail/images are URLs (imports) and handle differently
                title: state.createListing?.title,
                price: (state.createListing?.price) ? parseFloat(state.createListing?.price?.split(" ")?.[0])?.toFixed(2) : null,
                postage: state.createListing?.postage,
                options: state.createListing?.options,
                variations: state.createListing?.variations?.map(v => {
                    var tmpPrice = parseFloat(v.price);

                    return { ...v, price: tmpPrice?.toFixed(2) }
                }),
                currencies: state.createListing?.currencies,
                description: state.createListing?.description
            }
        ));

        dispatch({
            type: "setCreateListing",
            value: null
        });
    }, [state.createListing]);

    useEffect(() => {
        if (state.authenticated === null) return;

        if (!state.authenticated) {
            dispatch({
                type: "promptLogin",
                value: "/create-listing"
            });

            return navigate("/", { replace: true });
        };
    }, [state.authenticated]);

    useEffect(() => {
        if (!state.inputVariations) return;

        setInputs(values => (
            { ...values, variations: state.inputVariations }
        ));

        $("div.input-modal, section").removeClass("active");

        handleOptionReset();
    }, [state.inputVariations]);

    useEffect(() => {
        if (!state.inputInternational) return;

        setInputs(values => (
            { ...values, internationalShipping: state.inputInternational }
        ));

        $("div.input-modal, section").removeClass("active");
    }, [state.inputInternational]);

    useEffect(() => {
        var priceRaw = inputs.domesticShipping;
        var priceString;

        if (priceRaw) {
            var priceString = (parseFloat(priceRaw) == 0) ? "FREE" : parseFloat(priceRaw).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 });
        } else {
            var priceString = "";
        };

        setInputs(values => ({ ...values, deliveryCost: priceString }));
    }, [inputs.domesticShipping]);

    function handleFocus(event) {
        // add jQuery event handler in useEffect to trigger this fnc
    };

    function toggleFree(value) {
        var domesticShipping = value ? "0" : "";

        setInputs(values => (
            { ...values, freeDomestic: value, domesticShipping: domesticShipping }
        ));
    };

    function handleChange(event) {
        const name = event.target.name;
        var value = event.target.value;

        if (name == "defaultPrice") {
            value = value.replace(/[^0-9.]/g, "");
            var variationsCopy = inputs.variations;

            setInputs(values => (
                {
                    ...values,
                    variations: variationsCopy?.map(v => {
                        return {
                            ...v,
                            price: parseFloat(value)
                        }
                    })
                }
            ));
        };

        if (name == "defaultStock") {
            value = value.replace(/[^0-9.]/g, "");
            var variationsCopy = inputs.variations;

            setInputs(values => (
                {
                    ...values,
                    variations: variationsCopy?.map(v => {
                        return {
                            ...v,
                            stock: parseInt(value)
                        }
                    })
                }
            ));
        };

        if (name == "category") {
            setInputs(values => (
                { ...values, subcategory: Object.keys(data.categories[value]["subcategories"])[0] }
            ));
        };

        if (name.includes("option")) {
            var [, index] = name.split("-");
            var optionsCopy = inputs.options;

            if (optionsCopy[index].name == "custom") {
                optionsCopy[index].custom = value;
            } else {
                optionsCopy[index].name = value;
            };

            setInputs(values => (
                { ...values, options: optionsCopy }
            ));
        };

        if (name.includes("attribute")) {
            var [, index] = name.split("-");
            var attributes = {};
            var newVariations = [];

            attributes[0] = (inputs["attribute-0"] || "")?.split(",");
            attributes[1] = (inputs["attribute-1"] || "")?.split(",");
            attributes[2] = (inputs["attribute-2"] || "")?.split(",");
            attributes[index] = value.split(",");

            attributes[0].forEach((v0, i0) => {
                attributes[1].forEach((v1, i1) => {
                    attributes[2].forEach((v2, i2) => {
                        newVariations.push({ ["option0"]: v0.trim().replace(/\s\s+/g, " "), ["option1"]: v1.trim().replace(/\s\s+/g, " "), ["option2"]: v2.trim().replace(/\s\s+/g, " "), price: inputs.defaultPrice, stock: inputs.defaultStock });
                    });
                });
            });

            newVariations = newVariations?.map((v, i) => {
                return {
                    id: i,
                    ...v
                }
            });

            setInputs(values => (
                { ...values, variations: newVariations }
            ));
        };

        if (name == "price" || name == "domesticShipping") {
            value = value.replace(/[^0-9.]/g, "");

            // var [rawInt, rawDec] = rawValue.split(".");

            // if (rawInt.length > 9) {
            //     var trimmedInt = rawInt.slice(0, 9);

            //     if (rawValue.search(/[.]/g) != -1) {
            //         rawValue = trimmedInt + "." + rawDec;
            //     } else {
            //         rawValue = trimmedInt;
            //     };
            // };

            // value = parseFloat(rawValue || "0").toLocaleString("en-US", { maximumFractionDigits: 2, roundingMode: "floor" });

            // if (rawValue.search(/[.]/g) != -1) {
            //     if (value.search(/[.]/g) == -1) {
            //         value += rawValue.slice(rawValue.search(/[.]/g), rawValue.length).slice(0, 3);
            //     } else {
            //         value = parseFloat(rawValue).toLocaleString("en-US", { maximumFractionDigits: 0, roundingMode: "floor" }) + rawValue.slice(rawValue.search(/[.]/g), rawValue.length).slice(0, 3);;
            //     };
            // };
        };

        if (name == "stock") {
            value = value.replace(/[^0-9.]/g, "");
            var variationsCopy = inputs.variations;

            variationsCopy[0].stock = value;

            setInputs(values => (
                { ...values, variations: variationsCopy }
            ));
        };

        if ($(event.target).attr("type") == "checkbox") {
            var tmpArray = [];

            $("[name='" + $(event.target).attr("name") + "']:checked").each((i, e) => {
                supportedCurrencies.map((currency) => {
                    if (currency.name == e.value) {
                        tmpArray.push({ "contract": currency.contract, "sym": currency.precision + "," + currency.ticker, "name": currency.name, "logo": currency.logo });
                    };
                });
            });

            value = tmpArray;
        };

        if (name == "description") {
            value = encodeURIComponent(value);
        };

        setInputs(values => (
            { ...values, [name]: value }
        ));
    };

    function handleImageChange(event) {
        const name = event.target.name;
        const value = event.target.value;
        const files = event.target.files;

        if (files?.length + inputs?.images?.length > maxImagesLength) {
            // add the files that aren't over the limit to the inputs list

            $("label[for='images'], span.images-left").addClass("limit");

            setTimeout(() => {
                $("label[for='images'], span.images-left").removeClass("limit");
            }, 1000);

            return event.preventDefault();
        };

        var validSize = Object.keys(files).every(file => files[file].size <= maxImagesSize);

        if (!validSize) {
            alert("You cannot upload photos larger than " + Math.floor(maxImagesSize / 1000000) + "MB");
            // have the max size text flash red

            return event.preventDefault();
        };

        var validFormat = Object.keys(files).forEach(file => console.log(files[file]));

        var validFormat = Object.keys(files).every(file => acceptedImageTypes.includes(files[file].type));

        if (!validFormat) {
            alert("You can only upload JPEG, JPG, PNG, WEBP");

            return event.preventDefault();
        };

        var photoArr = inputs.images || [];

        var thumbnailImg = photoArr.length < 1 ? files[0] : inputs.thumbnail;

        Object.keys(files).forEach(file => photoArr.push(files[file]));

        setInputs(values => (
            { ...values, [name]: photoArr, thumbnail: thumbnailImg }
        ));
    };

    function handleRemoveImage(index) {
        var imagesCopy = inputs.images;

        imagesCopy.splice(index, 1);

        setInputs(values => (
            { ...values, images: imagesCopy, thumbnail: imagesCopy[0] }
        ));
    };

    function handleAddVideo(event) {
        event.preventDefault();

        //$("div.input-modal").addClass("active").find("section.add-video").addClass("active");
    };

    function handleThumbnail(event, image) {
        if ($(event.target).parent().attr("id").split("-")[1] == inputs.scrollTop) handleScroll(false);
        if ($(event.target).parent().attr("id").split("-")[1] == inputs.scrollBottom) handleScroll(true);

        $(event.target).parent().addClass("active").siblings().removeClass("active");

        setInputs(values => (
            { ...values, thumbnail: image.src || image }
        ));
    };

    function handleScroll(increment) {
        if (increment) {
            if (inputs.scrollBottom > inputs.images.length - 2) return;

            setInputs(values => (
                { ...values, scrollTop: inputs.scrollTop + 1, scrollBottom: inputs.scrollBottom + 1 }
            ));

            document.getElementById("previews").scrollBy({ top: 0, left: 102, behavior: "smooth" });
        } else {
            if (inputs.scrollTop < 1) return;

            setInputs(values => (
                { ...values, scrollTop: inputs.scrollTop - 1, scrollBottom: inputs.scrollBottom - 1 }
            ));

            document.getElementById("previews").scrollBy({ top: 0, left: -102, behavior: "smooth" });
        };
    };

    function handleOptionChange(event, manual) {
        // initialise vars
        var selectedOptions = {};
        var selectedVariation = { id: -1, price: inputs.price, stock: 0 };

        // construct object with currently selected options
        inputs.options.forEach((option, optionIndex) => {
            selectedOptions["option" + optionIndex] = $("input[name='option" + optionIndex + "']:checked").val();
        });

        // iterate variations and check if the option values match the currently selected option values
        inputs.variations.forEach((variation, variationIndex) => {
            var variationMatch = true;

            Object.keys(selectedOptions)?.map((optionName) => {
                if (!variationMatch) return;

                if (selectedOptions[optionName] != variation[optionName]) variationMatch = false;
            });

            if (variationMatch) {
                selectedVariation.id = variationIndex;
                selectedVariation.price = variation.price;
                selectedVariation.stock = variation.stock;
            };
        });

        // set the selected variation id, price, stock
        setInputs(values => (
            { ...values, variation: selectedVariation.id, price: selectedVariation.price, stock: selectedVariation.stock }
        ));

        // check which option combinations are available with the currently selected options

        // make non-selected options disabled, if available, re-enable
        //$(".listing-preview .options input").not(":checked").attr("disabled", true);

        // iterate selected options and construct array of which ones need disabling
        Object.keys(selectedOptions)?.map((optionName) => {
            // iterate variations if optionName val == variation optionName val
            inputs.variations.forEach((variation, variationIndex) => {
                if (!variation[optionName]) return;

                if (variation[optionName] == selectedOptions[optionName]) {
                    Object.keys(variation)?.map(name => {
                        if (name.includes("option") && name != optionName) {
                            $("input[name='" + name + "'][value='" + variation[name] + "']").attr("disabled", false);
                        }
                    });
                };
            });
        });
    };

    function handleOptionReset(fullReset) {
        if (inputs.options.length > 0) {
            inputs.options.forEach((option, optionIndex) => {
                var optionInputs = $("input[name='option" + optionIndex + "']");

                for (let i = 0; i < optionInputs.length; i++) {
                    $(optionInputs[i]).prop("checked", false).prop("disabled", false);

                    if (i == 0 && !fullReset) $(optionInputs[i]).prop("checked", true);
                };
            });

            if (!fullReset) handleOptionChange({ target: { name: "option0", value: $("input[name='option0']").first().val() } });
        };
    };

    function handleTabClick(event) {
        var id = $(event.target).attr("id");

        $(event.target).addClass("active").siblings().removeClass("active");

        $(".tab-content").find("." + id).css("display", "block").siblings().css("display", "none");

        // display tab info
    };

    function handleAddOption(event) {
        event.preventDefault();

        if (inputs.options.length > 2) return;

        setInputs(values => (
            { ...values, options: [...inputs.options, {}] }
        ));
    };

    function handleRemoveOption(index) {
        var optionsCopy = inputs.options;

        optionsCopy.splice(index, 1);

        setInputs(values => (
            { ...values, options: optionsCopy }
        ));
    };

    function handleAddVariation(event) {
        event.preventDefault();

        $("div.input-modal").addClass("active").find("section.add-variation").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "add-variation",
                options: inputs.options,
                variations: inputs.variations
            }
        });
    };

    function handleAddInternational(event) {
        event.preventDefault();

        $("div.input-modal").addClass("active").find("section.add-international").addClass("active");

        dispatch({
            type: "setModal",
            value: {
                action: "add-international",
                internationalShipping: inputs.internationalShipping
            }
        });
    };

    function handleImport(event) {
        event.preventDefault();

        $("div.input-modal").addClass("active").find("section.import-listing").addClass("active");
    };

    function handleSaveTemplate(event, button) {
        event.preventDefault();

        var buttonHtml = $(button).html();

        $(button).html("<i class='fad fa-spinner-third'></i> Saving").attr("disabled", true);

        var convertedImages = [];

        function saveTemplate() {
            Listing.saveTemplate(state.session.auth.actor, JSON.stringify({ "inputs": inputs, "images": convertedImages })).then(res => {
                $("div.error-modal").addClass("success").find("div.text").html("Template saved successfully");

                $(button).html(buttonHtml).attr("disabled", false);
            }).catch(err => {
                var { message } = err.responseJSON || { message: err.responseText };

                $("div.error-modal").addClass("error").find("div.text").html(message || "There was an internal error with your request, please try again");

                $(button).html(buttonHtml).attr("disabled", false);
            });
        };

        if (inputs.images) {
            inputs.images.forEach(async (i, index) => {
                const toBase64 = file => new Promise((resolve, reject) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => resolve(reader.result);
                    reader.onerror = reject;
                });

                convertedImages[index] = { name: i.name, type: i.type, base64: await toBase64(i) };

                if (convertedImages.length == inputs.images.length) {
                    saveTemplate();
                };
            });
        } else {
            saveTemplate();
        };
    };

    function handleLoadTemplate(event) {
        event.preventDefault();

        navigator.clipboard.readText().then(paste => {
            var listingObject = JSON.parse(paste);

            if (listingObject.images.length > 0) {
                var fileArr = [];

                listingObject.images.forEach(async (i, index) => {
                    console.log(i.name, index);
                    const res = await fetch(i.base64);
                    const blob = await res.blob();

                    fileArr[index] = new File([blob], i.name, { type: i.type });

                    //fileArr.push(new File([blob], i.name, { type: i.type }));

                    if (fileArr.length == listingObject.images.length) {
                        return setInputs(values => (
                            { ...values, ...listingObject.inputs, thumbnail: fileArr[0], images: fileArr }
                        ));
                    };
                });
            } else {
                setInputs(values => (
                    { ...values, ...listingObject.inputs }
                ));
            };
        });
    };

    // Gets the Country Name based on the users set country name for their account
    const userCountryName = countryCodes.find((item) => item.countryCode === state?.account?.country)?.name

    useEffect(() => {
        setInputs(values => ({
            ...values,
            location: state?.account.country
        }));
    }, [state.account])

    function handleClear(event) {
        event.preventDefault();

        dispatch({
            type: "setCreateListing",
            value: { description: "", category: "", scrollTop: 0, scrollBottom: 3, option0: [], option1: [], option2: [], options: [], variation: 0, variations: [{}], internationalShipping: [], freeDomestic: false }
        });
    };

    function handleSubmit(event) {
        event.preventDefault();

        if (!inputs.images || inputs.images?.length < 1) {
            window.scrollTo({ top: $("label[for='images']").offset().top - 36, left: 0, behavior: "smooth" });

            $("label[for='images']").css("border-color", "#d35f5f");

            setTimeout(() => {
                $("label[for='images']").css("border-color", "");
            }, 1000);

            return;
        };

        if (inputs.options.length < 1) {
            if (!inputs.price) {
                window.scrollTo({ top: $("label[for='price']").offset().top - 24, left: 0, behavior: "smooth" });

                $("input[name='price']").css("border-bottom-color", "#d35f5f");

                setTimeout(() => {
                    $("input[name='price']").css("border-bottom-color", "");
                }, 1000);

                return;
            } else {
                inputs.variations[0] = {
                    id: 0,
                    option0: "",
                    option1: "",
                    option2: "",
                    price: parseFloat(inputs.price).toFixed(6) + " XUSDC",
                    stock: parseInt(inputs.stock)
                };
            };

            if (!inputs.stock) {
                window.scrollTo({ top: $("label[for='stock']").offset().top - 24, left: 0, behavior: "smooth" });

                $("input[name='stock']").css("border-bottom-color", "#d35f5f");

                setTimeout(() => {
                    $("input[name='stock']").css("border-bottom-color", "");
                }, 1000);

                return;
            };
        } else {
            if (!inputs.variations || inputs.variations.length < 1) {
                window.scrollTo({ top: $("label[for='attributes']").offset().top - 24, left: 0, behavior: "smooth" });

                $("select[name='option-0']").css("border-bottom-color", "#d35f5f");

                setTimeout(() => {
                    $("select[name='option-0']").css("border-bottom-color", "");
                }, 1000);

                return;
            } else {
                if (!inputs.variations[0]?.option0) {
                    window.scrollTo({ top: $("label[for='attributes']").offset().top - 24, left: 0, behavior: "smooth" });

                    $("select[name='option-0']").css("border-bottom-color", "#d35f5f");
                    $("input[name='attribute-0']").css("border-color", "#d35f5f");

                    setTimeout(() => {
                        $("select[name='option-0']").css("border-bottom-color", "");
                        $("input[name='attribute-0']").css("border-color", "");
                    }, 1000);

                    return;
                };

                if (!inputs.variations[0]?.stock || !inputs.variations[0]?.price) {
                    window.scrollTo({ top: $("label[for='variations']").offset().top - 24, left: 0, behavior: "smooth" });

                    $("label.add-variation").css("border-color", "#d35f5f");

                    setTimeout(() => {
                        $("label.add-variation").css("border-color", "");
                    }, 1000);

                    return;
                };
            };
        };

        if ($("input[name='escrow']:checked").length < 1) {
            window.scrollTo({ top: $("label[for='escrow']").offset().top - 24, left: 0, behavior: "smooth" });

            $("input[name='escrow'] + label").css("border-color", "#d35f5f");

            setTimeout(() => {
                $("input[name='escrow'] + label").css("border-color", "");
            }, 1000);

            return;
        };

        if ($("input[name='currencies']:checked").length < 1) {
            window.scrollTo({ top: $("div.payments").offset().top - 24, left: 0, behavior: "smooth" });

            $("input[name='currencies'] + label").css("border-color", "#d35f5f");

            setTimeout(() => {
                $("input[name='currencies'] + label").css("border-color", "");
            }, 1000);

            return;
        };

        var optionsCopy = [...inputs.options];

        optionsCopy.forEach(({ name }, index) => {
            optionsCopy[index] = { "descriptor": name, "id": index };
        });

        var variationsCopy = [...inputs.variations];

        variationsCopy.forEach((variation, index) => {
            variationsCopy[index] = { "id": index, "option0": variation?.option0 || "", "option1": variation?.option1 || "", "option2": variation?.option2 || "", "price": parseFloat(variation.price).toFixed(6) + " XUSDC", "stock": parseInt(variation.stock) };
        });

        var domesticShipping = {};
        var internationalShipping = {};
        var shippingCopy = [];

        domesticShipping[inputs.location] = parseFloat(inputs.domesticShipping).toFixed(2);
        shippingCopy.push({ country_code: inputs.location, price: parseFloat(inputs.domesticShipping).toFixed(6) + " XUSDC" });

        inputs.internationalShipping.forEach(({ country_code, price }, index) => {
            if (regionCodesDictionary[country_code]) {
                regionCodesDictionary[country_code].forEach(cc => {
                    if (internationalShipping[cc]) return;

                    internationalShipping[cc] = parseFloat(price).toFixed(2);
                    shippingCopy.push({ country_code: cc, price: parseFloat(price).toFixed(6) + " XUSDC" });
                });
            } else {
                internationalShipping[country_code] = parseFloat(price).toFixed(2);
                shippingCopy[index].price = parseFloat(shippingCopy[index]?.price).toFixed(6) + " XUSDC";
            };
        });

        $("section.create-listing button.submit").html("<i class='fad fa-spinner-third'></i> Sending to blockchain");

        var showSuccess = () => {
            navigate("/account/listings");
        };

        var showError = (error) => {
            $("div.error-modal").addClass("error").find("div.text").html(error || "There was an error authenticating your request, please try again");

            $("section.create-listing button.submit").html(textStrings["submit_listing"][state.language]);
        };

        var uploadImages = (txId) => {
            Listing.uploadImages(state.session.auth.actor, txId, inputs.images).then(res => {
                showSuccess();
            }).catch(err => {
                var { message } = err.responseJSON || { message: err };

                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Listing.uploadImages(state.session.auth.actor, txId, inputs.images).then(res => {
                            showSuccess();
                        }).catch(err => {
                            var { message } = err.responseJSON || { message: err };

                            $("section.settings .form-alert").addClass("active").children(".message").html(message);

                            $("section.create-listing button.submit").html(textStrings["submit_listing"][state.language]);
                        });
                    }).catch(err => {
                        var { message } = err.responseJSON || { message: err };

                        showError(message);
                    });
                } else {
                    var { message } = err.responseJSON || { message: err };

                    showError(message);
                };
            });
        };

        Proton.createListing(state, {
            title: inputs.title,
            options: optionsCopy,
            variations: variationsCopy,
            shipping: shippingCopy,
            payments: inputs.currencies,
            escrow: (inputs.escrow === "true")
        }).then(tx => {
            $("section.create-listing button.submit").html("<i class='fad fa-spinner-third'></i> Creating listing");

            if (!tx.processed) return showError("The transaction has not been broadcast to the blockchain, please try again");

            var txId = tx.processed?.id || "";

            Listing.createListing(state.session.auth.actor, txId, inputs, domesticShipping, internationalShipping).then(res => {
                $("section.create-listing button.submit").html("<i class='fad fa-spinner-third'></i> Uploading images");

                uploadImages(txId);
            }).catch(err => {
                var { message } = err.responseJSON || { message: err };

                if (message.indexOf("Missing Cookie") != -1) {
                    Account.requestToken(state.session.auth.actor).then(res => {
                        Listing.createListing(state.session.auth.actor, txId, inputs, domesticShipping, internationalShipping).then(res => {
                            $("section.create-listing button.submit").html("<i class='fad fa-spinner-third'></i> Uploading images");

                            uploadImages(txId);
                        }).catch(err => {
                            var { message } = err.responseJSON || { message: err };

                            showError(message);
                        });
                    }).catch(err => {
                        showError(err);
                    });
                } else {
                    showError(message);
                };
            });
        }).catch(err => {
            showError(err.error?.details?.[0]?.message || err);
        });
    };

    const breadcrumbs = [
        { label: "Home", path: "/" },
        { label: textStrings?.["create_listing"]?.[state.language], path: "/create-listing" }
    ]

    const listingPreviewBreadcrumbs = [
        { label: "Home", path: "/" },
        { label: textStrings[inputs.category]?.[state.language] || "Category", path: "/create-listing" },
        { label: textStrings[inputs.subcategory]?.[state.language] || "Subcategory", path: "/create-listing" }
    ]

    return (
        <section className="create-listing">
            <div className="page-header">Create Listing</div>

            <div className="container">
                <div className="breadcrumb-wrapper">
                    <div className="breadcrumb">
                        <Breadcrumb crumbs={breadcrumbs} />
                    </div>
                </div>

                <div className="create-wrapper">
                    <div className="input-sidebar">
                        <div className="action-buttons">
                            {
                                /*
                                <button className="save" onClick={ (e) => handleSaveTemplate(e, e.target) }>Save Template</button>
                                <button className="load" onClick={ handleLoadTemplate }>Load Template</button>
                                */
                            }
                            <button className="clear" onClick={handleClear}>Clear Listing</button>
                        </div>

                        <form onSubmit={handleSubmit}>
                            <div className="input-group">
                                <label htmlFor="title">Title</label>

                                <input type="text" name="title" autoComplete="none" maxLength={maxTitleLength} value={inputs.title || ""} onChange={handleChange} aria-label="Title" required />

                                <span className="chars-left">{(inputs.title?.length || 0) + "/" + maxTitleLength + " characters"}</span>
                            </div>

                            <div className="input-group">
                                <label htmlFor="category">Category</label>

                                <select className="category" name="category" value={inputs.category || ""} onChange={handleChange} aria-label="Category" required>
                                    {
                                        Object.keys(data.categories)?.map((category, index) => (
                                            <option key={index} value={category}>{textStrings?.[category]?.[state.language]}</option>
                                        ))
                                    }
                                </select>

                                <i className="fal fa-chevron-down" />
                            </div>

                            <div className="input-group">
                                <label htmlFor="subcategory">Subcategory</label>

                                <select className="subcategory" name="subcategory" value={inputs.subcategory || ""} onChange={handleChange} aria-label="Sub-category" required>
                                    {
                                        Object.keys(data.categories?.[inputs.category || ""]?.subcategories)?.map((sub, index) => (
                                            <option key={index} value={sub}>{textStrings?.[sub]?.[state.language]}</option>
                                        ))
                                    }
                                </select>

                                <i className="fal fa-chevron-down" />
                            </div>

                            <div className="input-group images">
                                <label>Images</label>

                                <div className="image-buttons">
                                    <label htmlFor="images"><i className="fal fa-images" /> Add Images</label>

                                    { /* <label className="images disabled" onClick={ handleAddVideo }><i className="fal fa-film-alt" /> Add Video</label> */}
                                </div>

                                <input type="file" name="images" id="images" onChange={handleImageChange} accept=".jpg,.jpeg,.png,.webp" aria-label="Listig upload" multiple />

                                <span className="images-left">{(inputs.images?.length || 0) + "/" + maxImagesLength + " images"}</span>

                                <div className={"image-previews" + (inputs.images?.length > 0 ? "" : " empty")}>
                                    {
                                        inputs.images?.map((image, index) => (
                                            <div key={index} className="image-wrapper">
                                                {
                                                    (image.size) && (
                                                        <img src={URL.createObjectURL(image)} alt="listing preview" />
                                                    )
                                                }

                                                {
                                                    (image.src) && (
                                                        <img src={image.src} alt="listing preview" />
                                                    )
                                                }

                                                <i className="fal fa-times click" onClick={() => handleRemoveImage(index)} />
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="input-group">
                                <label htmlFor="condition">Condition</label>

                                <select className="condition" name="condition" value={inputs.condition || ""} onChange={handleChange} aria-label="Condition" required>
                                    <option value=""></option>
                                    {
                                        filterConditions.map((condition, index) => (
                                            <option key={index} value={condition}>{condition}</option>
                                        ))
                                    }
                                </select>

                                <i className="fal fa-chevron-down" />
                            </div>

                            <div className="input-group">
                                <label htmlFor="description">Description</label>

                                <textarea type="text" name="description" autoComplete="none" maxLength={maxDescriptionLength} value={decodeURIComponent(inputs.description || "")} onChange={handleChange} aria-label="Description" />

                                <span className="chars-left">{(inputs.description?.length || 0) + "/" + maxDescriptionLength + " characters"}</span>
                            </div>

                            <div className="input-group options">
                                <label htmlFor="attributes">Attributes</label>
                                {
                                    inputs.options?.map((option, index) => (
                                        <div className="relative" key={index}>
                                            <select name={"option-" + index} id={"option-" + index} value={option.name || ""} onChange={handleChange}>
                                                <option value=""></option>
                                                <option value="Color">Color</option>
                                                <option value="Size">Size</option>
                                                <option value="Pattern">Pattern</option>
                                                <option value="Type">Type</option>
                                                <option value="Model">Model</option>
                                                <option value="Edition">Edition</option>
                                                <option value="Flavor">Flavor</option>
                                                <option value="Capacity">Capacity</option>
                                                <option value="custom">Custom</option>
                                            </select>

                                            {
                                                (option.name == "custom") && (
                                                    <input type="text" name={"option-" + index} id={"option-" + index} value={option.custom || ""} onChange={handleChange} aria-label="Custom attribute" placeholder="Enter custom attribute" />
                                                )
                                            }

                                            <i className="fal fa-times click" onClick={() => handleRemoveOption(index)} />
                                        </div>
                                    ))
                                }

                                {
                                    // inputs.options.map((option, index) => (
                                    //     <div className="relative" key={ index }>
                                    //         <input type="text" name={ "option-" + index } autoComplete="none" maxLength="30" value={ option.name || "" } onChange={ handleChange }/>
                                    //         <i className="fal fa-times click" onClick={ () => handleRemoveOption(index)  } />
                                    //     </div>
                                    // ))
                                }

                                {
                                    (inputs.options.length < 3) && (
                                        <label className="add-option" onClick={handleAddOption}><i className="fal fa-list" /> Add Attribute</label>
                                    )
                                }

                                <span className="chars-left">{inputs.options.length + "/" + maxOptionLength + " options"}</span>
                            </div>

                            {
                                (inputs.options.length > 0 && inputs?.options?.[0]?.name || inputs?.options?.[1]?.name || inputs?.options?.[2]?.name) && (
                                    <div className="input-group options">
                                        <label>Attribute Values <span>(Separate with comma)</span></label>

                                        {
                                            (inputs?.options?.[0]?.name) && (
                                                <div className="inline">
                                                    <label htmlFor="">{inputs?.options?.[0]?.name == "custom" ? inputs?.options?.[0]?.custom : inputs?.options?.[0]?.name}:</label>
                                                    <input type="text" name={"attribute-0"} placeholder="e.g. Red, Blue, Green, Pink" onChange={handleChange} aria-label="Attribute 1" />
                                                </div>
                                            )
                                        }

                                        {
                                            (inputs?.options?.[1]?.name) && (
                                                <div className="inline">
                                                    <label htmlFor="">{inputs?.options?.[1]?.name == "custom" ? inputs?.options?.[1]?.custom : inputs?.options?.[1]?.name}:</label>
                                                    <input type="text" name={"attribute-1"} placeholder="e.g. Small, Medium, Large" onChange={handleChange} aria-label="Attribute 2" />
                                                </div>
                                            )
                                        }

                                        {
                                            (inputs?.options?.[2]?.name) && (
                                                <div className="inline">
                                                    <label htmlFor="">{inputs?.options?.[2]?.name == "custom" ? inputs?.options?.[2]?.custom : inputs?.options?.[2]?.name}:</label>
                                                    <input type="text" name={"attribute-2"} placeholder="e.g. Matte, Gloss" onChange={handleChange} aria-label="Attribute 3" />
                                                </div>
                                            )
                                        }
                                    </div>
                                )
                            }

                            {
                                (inputs.options.length > 0 && inputs?.options?.[0]?.name || inputs?.options?.[1]?.name || inputs?.options?.[2]?.name) && (
                                    <div className="input-group options">
                                        <i className="fal fa-dollar-sign" />
                                        <label>Default Price</label>
                                        <input type="text" name="defaultPrice" value={inputs.defaultPrice || ""} onChange={handleChange} aria-label="Price" />
                                    </div>
                                )
                            }

                            {
                                (inputs.options.length > 0 && inputs?.options?.[0]?.name || inputs?.options?.[1]?.name || inputs?.options?.[2]?.name) && (
                                    <div className="input-group options">
                                        <label>Default Stock (Quantity)</label>
                                        <input type="text" name="defaultStock" value={inputs.defaultStock || ""} onChange={handleChange} aria-label="Quantity" />
                                    </div>
                                )
                            }

                            {
                                (inputs.options.length > 0 && inputs?.options?.[0]?.name || inputs?.options?.[1]?.name || inputs?.options?.[2]?.name) && (
                                    <div className="input-group variations">
                                        <label htmlFor="variations">Variations</label>

                                        <label className="add-variation" onClick={handleAddVariation}><i className="fal fa-th-list" /> Edit Variations</label>
                                    </div>
                                )
                            }

                            {
                                ((inputs.options[0]?.name || "") == "" && (inputs.options[1]?.name || "") == "" && (inputs.options[2]?.name || "") == "") && (
                                    <>
                                        <div className="input-group">
                                            <i className="fal fa-dollar-sign" />

                                            <label htmlFor="price">Price</label>

                                            <input type="text" name="price" autoComplete="none" maxLength="9" value={inputs.price || ""} onChange={handleChange} aria-label="Price" />
                                        </div>

                                        <div className="input-group">
                                            <label htmlFor="stock">Stock (Quantity)</label>

                                            <input type="text" name="stock" autoComplete="none" value={inputs.stock} onChange={handleChange} aria-label="Quantity" />
                                        </div>

                                        {
                                            /*
                                                <div className="input-group">
                                                    <label htmlFor="sku">SKU</label>

                                                    <input type="text" name="sku" autoComplete="none" maxLength="28" value={ inputs.sku } onChange={ handleChange }/>
                                                </div>
                                            */
                                        }
                                    </>
                                )
                            }

                            <div className="input-group escrow">
                                <label htmlFor="escrow">Escrow Service</label>

                                <div className="radio-wrapper">
                                    <div>
                                        <input type="radio" name="escrow" id="escrow-true" value="true" onChange={handleChange} />
                                        <label htmlFor="escrow-true"><i className="fal fa-shield-check" /> Yes</label>
                                    </div>

                                    <div>
                                        <input type="radio" name="escrow" id="escrow-false" value="false" onChange={handleChange} />
                                        <label htmlFor="escrow-false"><i className="fal fa-shield" /> No</label>
                                    </div>
                                </div>
                            </div>

                            {
                                /*
                                <div className="input-group escrow">
                                    <label htmlFor="description">Buyer KYC Status</label>

                                    <div className="radio-wrapper">
                                        <div>
                                            <input type="radio" name="buyerKYC" id="buyerKYC-true" value="true" onChange={ handleChange } disabled/>
                                            <label htmlFor="buyerKYC-true"><i className="fal fa-shield-check" /> Required</label>
                                        </div>

                                        <div>
                                            <input type="radio" name="buyerKYC" id="buyerKYC-false" value="false" onChange={ handleChange } checked disabled/>
                                            <label htmlFor="buyerKYC-false"><i className="fal fa-shield" /> Not Required</label>
                                        </div>
                                    </div>
                                </div>
                                */
                            }

                            <div className="input-group payments">
                                <label htmlFor="description">Payment Methods</label>

                                <div className="checkbox-wrapper">
                                    {
                                        supportedCurrencies.map((currency, index) => (
                                            <div key={index}>
                                                <input type="checkbox" name="currencies" id={currency.name} value={currency.name} onChange={handleChange} />
                                                <label htmlFor={currency.name}><img src={currency.logo} alt={currency.name} /> {currency.name}</label>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>

                            <div className="input-group">
                                <label htmlFor="location">Item Location</label>
                                <select className="location" name="location" value={inputs.location || ""} onChange={handleChange} aria-label="Item location" required>
                                    <option value={state?.account.deliveryAddress[0]?.country} style={{ display: "none" }}>{userCountryName}</option>
                                    {
                                        countryCodes.map((country, index) => (
                                            <option key={index} value={country.countryCode}>{country.name}</option>
                                        ))
                                    }
                                </select>

                                <i className="fal fa-chevron-down" />
                            </div>

                            <div className="input-group shipping">
                                <i className="fal fa-dollar-sign" />

                                <label htmlFor="domesticShipping">Domestic Shipping</label>

                                <input type="text" name="domesticShipping" autoComplete="none" maxLength="5" value={inputs.domesticShipping || ""} onChange={handleChange} disabled={inputs.freeDomestic} aria-label="Domestic shipping" required />

                                <div className="free">
                                    <input type="checkbox" id="domestic-free" name="domestic-free" value={inputs.freeDomestic || false} onChange={() => toggleFree(!inputs.freeDomestic)} aria-label="Domestic shipping checkmark" />
                                    <label htmlFor="domestic-free">Free</label>
                                </div>
                            </div>

                            <div className="input-group shipping">
                                <label>International Shipping</label>

                                <label className="add-international" onClick={handleAddInternational}><i className="fal fa-mail-bulk" /> Add International Shipping</label>
                            </div>

                            <button className="submit" type="submit">{textStrings["submit_listing"][state.language]}</button>
                        </form>
                    </div>

                    <div className="preview-wrapper n768">
                        <div className="preview-visibility"><i className="fal fa-eye" /> Listing Preview</div>

                        <div className="listing-preview">
                            <div className="breadcrumb-wrapper">
                                <div className="breadcrumb">
                                    <Breadcrumb crumbs={listingPreviewBreadcrumbs} />
                                </div>
                            </div>

                            <div className="content-wrapper">
                                <div className="top-wrapper">
                                    <div className="thumbnail-wrapper">
                                        <div className="thumbnail">
                                            {
                                                (inputs.thumbnail) && (
                                                    (typeof inputs.thumbnail == "string") && (
                                                        (inputs.thumbnail?.search("youtube") != -1) && (
                                                            <iframe title="Youtube Embed" src={"https://youtube.com/embed/" + (inputs.thumbnail?.split("v=")[1])} frameBorder="0"></iframe>
                                                        )
                                                    )
                                                )
                                            }

                                            {
                                                (inputs.thumbnail?.size) && (
                                                    <img src={URL.createObjectURL(inputs.thumbnail)} alt="listing preview" />
                                                )
                                            }

                                            {
                                                (!inputs.thumbnail?.size) && (
                                                    <img src={inputs.thumbnail} alt="listing preview" />
                                                )
                                            }

                                            {
                                                (!inputs.thumbnail) && (
                                                    <img src={placeholderThumbnail} alt="placeholder thumbnail" />
                                                )
                                            }
                                        </div>

                                        <div className="previews-wrapper">
                                            <div className="previews" id="previews">
                                                {
                                                    (inputs.images) && (
                                                        inputs.images?.map((image, index) => (
                                                            <div className={"image click " + (index == 0 ? "active" : "")} key={index} id={"image-" + index} onClick={event => handleThumbnail(event, image)}>
                                                                {
                                                                    ((image.src || "")?.search("youtube") == -1 && image.size) && (
                                                                        <img src={URL.createObjectURL(image)} alt="youtube thumbnail" />
                                                                    )
                                                                }

                                                                {
                                                                    ((image.src || "")?.search("youtube") == -1 && !image.size) && (
                                                                        <img src={image.src} alt="youtube thumbnail" />
                                                                    )
                                                                }

                                                                {
                                                                    ((image.src || "")?.search("youtube") != -1) && (
                                                                        <>
                                                                            <i className="fad fa-play-circle" />
                                                                            <img src={"https://i.ytimg.com/vi/" + (image?.src?.split("v=")[1]) + "/hqdefault.jpg"} alt="youtube thumbnail" />
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                        ))
                                                    )
                                                }

                                                {
                                                    ((inputs.images?.length || 0) < 4) && (
                                                        Array.apply(0, Array(4 - (inputs.images?.length || 0)))?.map((x, i) => (
                                                            <div key={i} className="image click">
                                                                <img src={placeholderThumbnail} alt="placeholder thumbnail" />
                                                            </div>
                                                        ))
                                                    )
                                                }
                                            </div>
                                        </div>
                                    </div>

                                    <div className="summary-wrapper">
                                        <div className="title">{inputs.title || "Title"}</div>

                                        <div className="price">
                                            {
                                                (inputs.price && parseFloat(inputs.price) > 0) && (
                                                    parseFloat(inputs.price).toLocaleString("en-US", { style: "currency", currency: "USD", minimumFractionDigits: 2 })
                                                )
                                            }

                                            {
                                                (!inputs.price || parseFloat(inputs.price) == 0) && (
                                                    "Price"
                                                )
                                            }

                                            {
                                                ((inputs.deliveryCost || "") != "") && (
                                                    <div className="postage">+ <span className={inputs.deliveryCost?.toLowerCase()}>{inputs.deliveryCost}</span> shipping</div>
                                                )
                                            }
                                        </div>



                                        <div className="reviews" title="0.0">
                                            <div>
                                                <i className="far fa-star" />
                                                <i className="far fa-star" />
                                                <i className="far fa-star" />
                                                <i className="far fa-star" />
                                                <i className="far fa-star" />
                                            </div>
                                            <span>{"(0 ratings)"}</span>
                                        </div>

                                        <div className="stock">
                                            {(inputs.variations?.filter((variation, varIndex) => varIndex == inputs.variation)[0]?.stock || 0) > 0 ? <div className="in-stock"><i className="fas fa-check" /><span>In Stock</span></div> : <div className="out-stock"><i className="fas fa-times" /><span>Out of Stock</span></div>}
                                        </div>

                                        <div className="options">
                                            {
                                                (inputs.options.length < 1 || !inputs.options[0]?.name) && (
                                                    <span className="no-options">No options available</span>
                                                )
                                            }

                                            {
                                                (inputs.options.length > 0) && (
                                                    inputs.options?.map((option, index) => (
                                                        (inputs.options[index]?.name) && (
                                                            <div className="attribute" key={index}>
                                                                <span>{inputs.options?.[index]?.name == "custom" ? inputs.options?.[index]?.custom : inputs.options?.[index]?.name}:</span>
                                                                <div className="radio-wrapper">
                                                                    {
                                                                        inputs.variations?.map(variation => {
                                                                            return variation["option" + index]
                                                                        }).filter((value, index, self) => {
                                                                            return self.indexOf(value?.trim()) === index
                                                                        })?.map((variation, index2) => (
                                                                            (variation) && (
                                                                                <div key={index2}>
                                                                                    <input type="radio" id={variation} name={"option" + index} value={variation} disabled={inputs["option" + index]?.[index2]} onChange={handleOptionChange} />
                                                                                    <label htmlFor={variation} className="click">{variation}</label>
                                                                                </div>
                                                                            )
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    ))
                                                )
                                            }

                                            <div className="reset click" onClick={handleOptionReset}><i className="fal fa-redo" /> Reset Options</div>
                                        </div>

                                        <div className="checkout">
                                            <div className="row">
                                                <button className="purchase"><i className="fas fa-tag" /> Purchase</button>
                                            </div>

                                            <div className="row">
                                                <button className="cart add-cart"><i className="fas fa-shopping-cart" /> Add To Cart</button>

                                                <button className="wishlist"><i className="fas fa-heart" /> Wishlist</button>
                                            </div>
                                        </div>

                                        <div className="currencies">
                                            <span>Payment Methods:</span>
                                            {
                                                inputs.currencies?.map((currency, index) => {
                                                    if (index < 4) {
                                                        return (
                                                            <img key={index} src={currency.logo} alt={currency.name} title={currency.name} />
                                                        );
                                                    } else {
                                                        if (index < 5 && inputs.currencies.length < 6) {
                                                            return (
                                                                <img key={index} src={currency.logo} alt={currency.name} title={currency.name} />
                                                            );
                                                        } else {
                                                            return (
                                                                <div key={index} className="overflow click">
                                                                    <div className="number">+{index + 1 - 4}</div>
                                                                </div>
                                                            );
                                                        };
                                                    };
                                                })
                                            }
                                        </div>

                                        {
                                            (inputs.currencies?.filter(currency => currency.name == "STRX").length > 0) && (
                                                <div className="cashback">
                                                    <span>Cashback Eligibility:</span>

                                                    <div className="amount">${(parseFloat(inputs.price || 0) / 20).toFixedStatic(2)}</div>
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>

                                <div className="bottom-wrapper">
                                    <div className="description-tabs">
                                        <button className="tablink active" id="description" onClick={handleTabClick}>Description</button>
                                        <button className="tablink" id="shipping" onClick={handleTabClick}>Shipping</button>
                                        <button className="tablink" id="reviews" onClick={handleTabClick}>Reviews (0)</button>
                                    </div>

                                    <div className="tab-content">
                                        <div className="description w3-animate-top">
                                            <p dangerouslySetInnerHTML={{ __html: decodeURIComponent((inputs.description || "Description")?.replaceAll("<script>", "").replaceAll("</script>", "").replaceAll("<script/>", "").replaceAll("%0A", "</br>")) }} />
                                        </div>

                                        <div className="shipping w3-animate-top" style={{ display: "none" }}>
                                            <p>Shipping content</p>
                                        </div>

                                        <div className="reviews w3-animate-top" style={{ display: "none" }}>
                                            <p>Review content</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default CreateListing;
